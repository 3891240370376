/* SEARCH-TAG: page-template  */
import React, {
    Suspense,
    lazy,
    useState,
    useEffect,
    useContext
} from 'react';
import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import HeadTags from 'TMComponents/HeadTags';
import Main from 'TMComponents/Layout/Main';
import seoMetaTagProps from 'TMPropTypes/seoMetaTagProps';
import Canonical from 'TMComponents/Canonical';
import isServerSideRender from 'TMUtils/isServerSideRender';
import { BannerContext } from 'TMComponents/Banner';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import { StickyFooterContextProvider } from 'TMComponents/StickyFooter';
import { LayoutContext } from 'TMComponents/Layout';
import Hero from 'TMComponents/Hero';

const ContentWrapper = styled.div`
    height: 100%;
    margin: 0 auto;
    padding-top: 48px;
    width: 100%;
`;
const ServerSideRender = isServerSideRender();
const Content = lazy(() => import('TMComponents/Content'));
const isBannerAllowed = true;
const isCrumbsAllowed = false;

const HomePage = ({ data: { homePage } }) => {
    const [concealerHub, setConcealerHub] = useState({});
    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setIsNavHidden(false);
        setShowBanner(isBannerAllowed);
        setCrumbs({
            show: isCrumbsAllowed
        });
    }, []);

    return (
        <StickyFooterContextProvider
            value={{ concealerHub, setConcealerHub }}>
            <Main
                isBannerAllowed={isBannerAllowed}
                isCrumbsAllowed={isCrumbsAllowed}>
                <HeadTags tags={homePage.seoMetaTags.tags} />
                <Canonical />
                <Hero
                    content={homePage.heroContent}
                    desktopImage={homePage.headerImageDesktop}
                    mobileImage={homePage.headerImageMobile}
                    desktopImagePosition={homePage.desktopImagePosition}
                    mobileImagePosition={homePage.mobileImagePosition}
                    backgroundVariant={homePage.backgroundVariant}
                    mobileImageWidth={homePage.mobileImageWidth}
                    mobileImageAsBackground={
                        homePage.mobileImageAsBackground
                    }
                    mobileBackgroundBottomAlign={
                        homePage.mobileBackgroundBottomAlign
                    }
                />
                {!ServerSideRender && (
                    <ContentWrapper>
                        <Suspense fallback={<div />}>
                            <Content
                                content={homePage.content}
                                isPageContent
                            />
                        </Suspense>
                    </ContentWrapper>
                )}
            </Main>
        </StickyFooterContextProvider>
    );
};

/**
 * Fetch page data
 */
export const pageQuery = graphql`
    query {
        homePage: datoCmsHomePage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            headerImageDesktop {
                gatsbyImageData(
                    width: 550
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }

            headerImageMobile {
                gatsbyImageData(
                    width: 400
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            id: originalId
            desktopImagePosition
            mobileImagePosition
            mobileImageAsBackground
            mobileBackgroundBottomAlign
            mobileImageWidth
            backgroundVariant
            heroContent {
                ...buttonFields
                ...panelFields
                ...stickyFooterConcealerFields
                ...textStructuredFields
                ...imageFields
            }

            content {
                ...AppStoreLinkFields
                ...buttonFields
                ...cardFields
                ...cardV2Fields
                ...faqFields
                ...iconWithContentFields
                ...imageFields
                ...imageListFields
                ...imageWithContentFields
                ...infoCardFields
                ...lottieFields
                ...panelFields
                ...sendAppLinkFields
                ...separatorFields
                ...stickyFooterFields
                ...stickyFooterConcealerFields
                ...tableFields
                ...textFields
                ...textStructuredFields
                ...trustpilotReviewFields
                ...trustpilotCarouselFields
                ...videoFields
            }
        }
    }
`;

/**
 * Prop types
 */
HomePage.propTypes = {
    data: shape({
        datoCmsHomePage: shape({
            title: string,
            _seoMetaTags: shape(seoMetaTagProps)
        })
    }).isRequired
};

export default HomePage;
