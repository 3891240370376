import { shape, string } from 'prop-types';

export default {
    attributes: shape({
        property: string,
        content: string
    }),
    tag: string,
    content: string
};
